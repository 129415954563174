export default [
  "IfcProduct",
  "IfcAnnotation",
  "IfcElement",
  "IfcBuildingElement",
  "IfcBeam",
  "IfcBuildingElementComponent",
  "IfcBuildingElementPart",
  "IfcReinforcingElement",
  "IfcReinforcingBar",
  "IfcReinforcingMesh",
  "IfcTendon",
  "IfcTendonAnchor",
  "IfcBuildingElementProxy",
  "IfcColumn",
  "IfcCovering",
  "IfcCurtainWall",
  "IfcDoor",
  "IfcFooting",
  "IfcMember",
  "IfcPile",
  "IfcPlate",
  "IfcRailing",
  "IfcRamp",
  "IfcRampFlight",
  "IfcRoof",
  "IfcSlab",
  "IfcStair",
  "IfcStairFlight",
  "IfcWall",
  "IfcWallStandardCase",
  "IfcWindow",
  "IfcDistributionElement",
  "IfcDistributionControlElement",
  "IfcDistributionFlowElement",
  "IfcDistributionChamberElement",
  "IfcEnergyConversionDevice",
  "IfcFlowController",
  "IfcElectricDistributionPoint",
  "IfcFlowFitting",
  "IfcFlowMovingDevice",
  "IfcFlowSegment",
  "IfcFlowStorageDevice",
  "IfcFlowTerminal",
  "IfcFlowTreatmentDevice",
  "IfcElectricalElement",
  "IfcElementAssembly",
  "IfcElementComponent",
  "IfcDiscreteAccessory",
  "IfcFastener",
  "IfcMechanicalFastener",
  "IfcEquipmentElement",
  "IfcFeatureElement",
  "IfcFeatureElementAddition",
  "IfcProjectionElement",
  "IfcFeatureElementSubtraction",
  "IfcEdgeFeature",
  "IfcChamferEdgeFeature",
  "IfcRoundedEdgeFeature",
  "IfcOpeningElement",
  "IfcFurnishingElement",
  "IfcTransportElement",
  "IfcVirtualElement",
  "IfcGrid",
  "IfcPort",
  "IfcDistributionPort",
  "IfcProxy",
  "IfcSpatialStructureElement",
  "IfcBuilding",
  "IfcBuildingStorey",
  "IfcSite",
  "IfcSpace",
  "IfcStructuralActivity",
  "IfcStructuralAction",
  "IfcStructuralLinearAction",
  "IfcStructuralLinearActionVarying",
  "IfcStructuralPlanarAction",
  "IfcStructuralPlanarActionVarying",
  "IfcStructuralPointAction",
  "IfcStructuralReaction",
  "IfcStructuralPointReaction",
  "IfcStructuralItem",
  "IfcStructuralConnection",
  "IfcStructuralCurveConnection",
  "IfcStructuralPointConnection",
  "IfcStructuralSurfaceConnection",
  "IfcStructuralMember",
  "IfcStructuralCurveMember",
  "IfcStructuralCurveMemberVarying",
  "IfcStructuralSurfaceMember",
  "IfcStructuralSurfaceMemberVarying",
  "IfcBeamStandardCase",
  "IfcChimney",
  "IfcColumnStandardCase",
  "IfcDoorStandardCase",
  "IfcMemberStandardCase",
  "IfcPlateStandardCase",
  "IfcShadingDevice",
  "IfcSlabElementedCase",
  "IfcSlabStandardCase",
  "IfcWallElementedCase",
  "IfcWindowStandardCase",
  "IfcCivilElement",
  "IfcActuator",
  "IfcAlarm",
  "IfcController",
  "IfcFlowInstrument",
  "IfcProtectiveDeviceTrippingUnit",
  "IfcSensor",
  "IfcUnitaryControlElement",
  "IfcAirToAirHeatRecovery",
  "IfcBoiler",
  "IfcBurner",
  "IfcChiller",
  "IfcCoil",
  "IfcCondenser",
  "IfcCooledBeam",
  "IfcCoolingTower",
  "IfcElectricGenerator",
  "IfcElectricMotor",
  "IfcEngine",
  "IfcEvaporativeCooler",
  "IfcEvaporator",
  "IfcHeatExchanger",
  "IfcHumidifier",
  "IfcMotorConnection",
  "IfcSolarDevice",
  "IfcTransformer",
  "IfcTubeBundle",
  "IfcUnitaryEquipment",
  "IfcAirTerminalBox",
  "IfcDamper",
  "IfcElectricDistributionBoard",
  "IfcElectricTimeControl",
  "IfcFlowMeter",
  "IfcProtectiveDevice",
  "IfcSwitchingDevice",
  "IfcValve",
  "IfcCableCarrierFitting",
  "IfcCableFitting",
  "IfcDuctFitting",
  "IfcJunctionBox",
  "IfcPipeFitting",
  "IfcCompressor",
  "IfcFan",
  "IfcPump",
  "IfcCableCarrierSegment",
  "IfcCableSegment",
  "IfcDuctSegment",
  "IfcPipeSegment",
  "IfcElectricFlowStorageDevice",
  "IfcTank",
  "IfcAirTerminal",
  "IfcAudioVisualAppliance",
  "IfcCommunicationsAppliance",
  "IfcElectricAppliance",
  "IfcFireSuppressionTerminal",
  "IfcLamp",
  "IfcLightFixture",
  "IfcMedicalDevice",
  "IfcOutlet",
  "IfcSanitaryTerminal",
  "IfcSpaceHeater",
  "IfcStackTerminal",
  "IfcWasteTerminal",
  "IfcDuctSilencer",
  "IfcFilter",
  "IfcInterceptor",
  "IfcVibrationIsolator",
  "IfcOpeningStandardCase",
  "IfcVoidingFeature",
  "IfcSurfaceFeature",
  "IfcFurniture",
  "IfcSystemFurnitureElement",
  "IfcGeographicElement",
  "IfcSpatialElement",
  "IfcExternalSpatialStructureElement",
  "IfcExternalSpatialElement",
  "IfcSpatialZone",
  "IfcStructuralCurveAction",
  "IfcStructuralSurfaceAction",
  "IfcStructuralCurveReaction",
  "IfcStructuralSurfaceReaction",
  "IfcContext",
  "IfcProject",
  "IfcProjectLibrary"
]