class TriBim {
  constructor() {
    // super(data);
    // this.data = data;
    this.hello = "Hello TriBim";
  }

  sayHello() {
    console.log('Hello toi');
  }
}

export default TriBim;